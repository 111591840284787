import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/lionparcel/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mapping-code',
    name: 'mapping-code',
    component: () => import('@/views/lionparcel/MappingCode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mapping-code/:id',
    name: 'mapping-code-details',
    component: () => import('@/views/lionparcel/EditMappingCode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create/mapping-code',
    name: 'mapping-code-create',
    component: () => import('@/views/lionparcel/CreateMappingCode.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mapping-token',
    name: 'mapping-token',
    component: () => import('@/views/lionparcel/MappingToken.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mapping-token/:id',
    name: 'mapping-token-details',
    component: () => import('@/views/lionparcel/EditMappingToken.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create/mapping-token',
    name: 'mapping-token-create',
    component: () => import('@/views/lionparcel/CreateMappingToken.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/lionparcel/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/lionparcel/NotFound.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    component: () => import('@/views/lionparcel/NotFound.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isLoggedIn) {
    next('/login')
  } else if (store.getters.isLoggedIn && to.path === '/login') {
    next('/')
  } else {
    next()
  }
})

export default router
